<template lang="html">
  <PanoList ref="List" :config="config"></PanoList>
</template>

<script>
  import PanoList from '@/components/PanoList'
  //import Modal from '@/components/Modal'

  export default {
    components: {
      PanoList,
    },
    data() {
      return {
        config: {
          panoUrl: `/Api/ES/GroupAdmin/Panos/${this.$route.params.groupId}`,
          panoApiUrl: `/Api/GroupAdmin/Panos/${this.$route.params.groupId}`,
          tagUrl: `/Api/GroupAdmin/Tags/${this.$route.params.groupId}/All`,
          params: { groupId: this.$route.params.groupId, },
          editName: 'GroupPanoEdit',
          viewPath: '/P/',
          status: [
            { name: '待切片', value: 0 },
            { name: '切片中', value: 1 },
            { name: '待收录', value: 2 },
            { name: '已收录', value: 4 },
            { name: '未收录', value: 3 },
          ]
        }
      }
    },
    created() {
    },
    computed: {
    },
    methods: {
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$refs.List.RouteUpdate(to, from, next)
      })
    },
    beforeRouteUpdate(to, from, next) {
      this.$refs.List.RouteUpdate(to, from, next)
      next()
    },

  }
</script>
<style lang="css">
</style>
