<template lang="html">
  <PanoList ref="List" :config="config"></PanoList>
</template>

<script>
  import PanoList from '@/components/PanoList'
  //import Modal from '@/components/Modal'

  export default {
    components: {
      PanoList,
    },
    data() {
      return {
        config: {
          title: '漫游管理',
          panoUrl: `/Api/GroupAdmin/Tours/${this.$route.params.groupId}`,
          tagUrl: `/Api/GroupAdmin/Tags/${this.$route.params.groupId}/All`,
          params: { groupId: this.$route.params.groupId, },
          editName: 'GroupTourEdit',
          createName: 'GroupTourCreate',
          viewPath: '/T/',
          productId: '漫游年费',
          status: [
            { name: '待收录', value: 0 },
            { name: '未收录', value: 1 },
            { name: '已收录', value: 2 },
          ]
        }
      }
    },
    created() {
    },
    computed: {
    },
    methods: {
    },
    beforeRouteEnter(to, from, next) {
      next(vm => {
        vm.$refs.List.RouteUpdate(to, from, next)
      })
    },
    beforeRouteUpdate(to, from, next) {
      this.$refs.List.RouteUpdate(to, from, next)
      next()
    },
  }
</script>
<style lang="css">
</style>
